<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on">
          <v-list-item-title>Change Status</v-list-item-title>
        </v-list-item>
        <!-- <v-btn color="red lighten-2" dark v-on="on">
          Click Me
        </v-btn> -->
      </template>

      <v-card>
        <ModelTitle title="Order Status" @close="dialog = false" />
        <v-card-title>
          <h2 class="display-1">{{ order.Vendor.name }}</h2>
          <v-spacer></v-spacer>
          <span class="title">{{ order.total | currency }}</span>
        </v-card-title>
        <!-- <v-card-title>
          <v-layout column>
            <div>Uncle Wireless</div>
            <small>Order # {{ order.localId }}</small>
          </v-layout>
        </v-card-title> -->
        <v-card-text>
          <small>Order # {{ order.localId }}</small>
          <v-layout row wrap>
            <v-flex>
              <div>
                <span
                  >Created At:
                  <span class="font-weight-bold">{{
                    order.date | moment("MM/DD/YYYY")
                  }}</span></span
                >
              </div>
              <div>
                <span
                  >View At:
                  <span class="font-weight-bold" v-if="order.read">{{
                    order.read | moment("MM/DD/YYYY")
                  }}</span
                  ><span>Not View Yet</span></span
                >
              </div>
              <div>
                <span
                  >User:
                  <span class="font-weight-bold">{{
                    order.User.name
                  }}</span></span
                >
              </div>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex shrink>
              <div>
                <span
                  >Order #
                  <span class="font-weight-bold">{{
                    order.localId
                  }}</span></span
                >
              </div>
              <div>
                <span
                  >Amount:
                  <span class="font-weight-bold">{{
                    order.total | currency
                  }}</span></span
                >
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-left mt-3">Status</div>
          <v-row justify="center" align="center">
            <v-col>
              <v-chip-group
                v-model="selection"
                item-value="name"
                center-active
                active-class="primary--text"
              >
                <v-chip v-for="tag in tags" :key="tag.name">
                  {{ tag.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" block @click="saveOrder()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import orderService from "@/modules/Inventory/PurchaseOrder/service";

export default {
  props: ["order"],
  data() {
    return {
      dialog: false,
      selection: 0,
      tags: [{ name: "Pending" }, { name: "Complete" }, { name: "Paid" }],
    };
  },
  methods: {
    saveOrder() {
      const completeDate = new Date();
      if (this.selection === 1) {
        return orderService
          .updateStatus(this.order.id, { completeDate: completeDate })
          .then(() => {
            // console.log("result", result);
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Order is completed!",
              });
            this.dialog = false;
            this.$emit("submit");
          });
      } else {
        this.dialog = false;
        this.$emit("submit");
      }
    },
  },
};
</script>

<style scoped></style>
